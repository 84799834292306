<template>
  <div>
    <div class="bg_gray"></div>
        <div class="addBankCard">
            <headbox title="新增银行卡"
            bgColor="#fff"
            :isbackPath="true"
            path="bank" />
            <div class="box">
                    <div class="bank">
                        <van-field v-model="bank.name" label="真实姓名" placeholder="请输入" :disabled="isDisable" />
                        <div class="bankName">
                            <van-field v-model="bank.bankName" label="银行名称" placeholder="请输入" ></van-field>
                            <van-uploader
                            :after-read="afterRead"
                            slot="suffix"
                            :preview-image="false"
                            ref="uploader">
                              <van-icon name="scan" />
                            </van-uploader>
                        </div>
                        <van-field v-model="bank.bankNumber" label="银行卡号" placeholder="请输入"/>
<!--                        <div class="check" v-if="edit">
                        <el-checkbox v-model="value"></el-checkbox> <span> 设置为默认银行卡</span>

                        </div>-->
                    </div>
                    <div class="save" :style="{background:isSave?'#ff2d2e':'#f7cdcd'}" @click="save(type)">{{saveName}}</div>
            </div>
        </div>
  </div>
</template>

<script>
import headbox from "@/components/head.vue";
import * as certifi from "@/utils/api";
import axios from 'axios'
import lrz from 'lrz'
export default {
    components:{
        headbox
    },
    data() {
        return {
            // 状态 取来区分 新增和编辑
            type:'',
            saveName:'保存',
            edit:false,
            // 是否可以保存
            isSave:false,
            id:'',
            // 文本框是否禁用
            isDisable:false,
            bank:{
                name:"",
                bankName:'',
                bankNumber:'',

            },
             value:false,
            // 是否上传中
             isloading: false,
             isloadingback: false,
             apiKey: "np5Z2o86jTLStdZxCnFPm6Hm",
             secretKey: "XBGA3SMlb2koPGMG4Xjo0zjEEFtxz64A",
             baidu_access_token: "",
             firstupsuccess: "",

             baiduBaseUrl:
               process.env.NODE_ENV == "dev" || process.env.NODE_ENV == "test" || process.env.NODE_ENV == "show" ? "/baidubce" : "https://api.shenzhenyht.com/baidubce",
             fileBaseUrl:
               process.env.NODE_ENV == "dev"
                 ? "/shenzhenyht"
                 : "https://shenzhenyht.oss-cn-shenzhen.aliyuncs.com",
             localtoken: localStorage.getItem("token"),
        }
    },
    mounted() {
        this.getAccess_token()
        this.type = this.$route.query.type
        if (this.type=='EditSave') {
             this.edit = true
            this.bank.name = this.$route.query.name
            this.bank.bankName = this.$route.query.bank
            this.id = this.$route.query.id
            // this.bank.bankNumber = JSON.parse(this.$route.query.num)
            this.saveName = '编辑保存'

        }
        let user =  JSON.parse(localStorage.getItem('userinfo'))
        if (user.auth == "DONE") {
              this.bank.name = user.realName
              this.isDisable = true
        }
    },
    watch:{
        bank:{
            handler(newVal){
                if (newVal.name && newVal.bankName && newVal.bankNumber) {
                    this.isSave = true
                }else{
                    this.isSave = false
                }
            },
            deep:true
        }
    },
    methods:{

    // 保存按钮
    save(item){
            if (item == 'addSave') {
                // 新增银行卡保存按钮
                const addData = {
                    num:this.bank.bankNumber,
                    name:this.bank.name,
                    bank:this.bank.bankName,
                    def:false
                }
                certifi.addBank(addData).then(res=>{
                  if (this.$route.query.path == 'pricingDeposit' ) {
                        this.$router.push({
                            name:"pricingDeposit"
                        })
                  }else{
                        this.$router.push({
                            name:"bank"
                        })
                  }

                })
            }else if(item =='EditSave'){
              if(!this.isSave) {
                return
              }
                // 编辑银行卡保存按钮
                const addData = {
                    num:this.bank.bankNumber,
                    name:this.bank.name,
                    bank:this.bank.bankName,
                    def:true
                }
                certifi.EditBank(addData,this.id).then(res=>{
                    this.$router.push({
                        name:'bank'
                    })
                })
            }
    },
    afterRead(file) {
      const than = this;
      if (this.isloading) {
        return false;
      }
      this.isloading = true;
      this.$Loading.show({text:'银行卡识别中...'})
      this.firstupsuccess = "";
      this.firstupsuccess = file.content;
      lrz(this.firstupsuccess, {
        quality: 0.2 //自定义使用压缩方式
      })
        .then(function(rst) {
          const data = {
            image: encodeURI(rst.base64).slice(22),
          };
          // 获取文件上传地址
          // console.log(this);
          than.getUploadUrl(rst.base64, "positive");
          than.baiduAi(data);
          //成功时执行
        })
        .catch(function(error) {
          this.isloading = false;
          // console.log("error:" + error);

          //失败时执行
        });
    },

    base64ToUint8Array(base64String) {
      const base64clear = this.firstupsuccess.substring(
        this.firstupsuccess.indexOf(",") + 1
      );
      const padding = "=".repeat((4 - (base64clear.length % 4)) % 4);
      const base64 = (base64clear + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },

    //第一步（获取上传接口）
    getUploadUrl(path, type) {
      const that = this;

      const data = {
        size: 1
      };
      certifi.apiFile(data).then(res => {
        that.bankcardFace = res[0].key;
        // localStorage.setItem(type, that.bankcardFace);
        that.uploadFile(path, res[0].url);
      });
    },
    // 第二步上传文件
    uploadFile(imgpath, url) {
      // 去掉url域名 保留后面
      const urllast =
        "/" +
        url
          .split("/")
          .slice(3)
          .join("/");
      const configs = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + this.localtoken
        }
      };
      const transformRequest = [
        function(data) {
          let ret = "";
          for (const it in data) {
            ret +=
              encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
          }
          return ret;
        }
      ];
      const imgarr = this.base64ToUint8Array();
      axios({
        url: this.fileBaseUrl + urllast,
        method: "put",
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: "Bearer " + this.localtoken
        },
        data: imgarr
      }).then(res => {
        // console.log("请求结果：", res);
      });
    },

    // 百度获取API
    getAccess_token() {
      const that = this;
      // 百度识别API
      axios
        .post(
          `${this.baiduBaseUrl}/oauth/2.0/token?grant_type=client_credentials&client_id=np5Z2o86jTLStdZxCnFPm6Hm&client_secret=XBGA3SMlb2koPGMG4Xjo0zjEEFtxz64A`
        )
        .then(res => {
          const { access_token } = res.data;
          this.baidu_access_token = access_token;
          // console.log(this.baidu_access_token);
        });
    },

    baiduAi(data) {
      const that = this;
      const token = that.baidu_access_token;
      const transformRequest = [
        function(data) {
          let ret = "";
          for (const it in data) {
            ret +=
              encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
          }
          return ret;
        }
      ];

      const configheaders = {
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      };
      axios
        .post(
          `https://aip.baidubce.com/baidubce/rest/2.0/ocr/v1/bankcard?access_token=` + token,
          data,
          { transformRequest },

        )
        .then(res => {
          // https://aip.baidubce.com/rest/2.0/ocr/v1/idcard
          // console.log('res1',res);
          if (
            res.data &&
            res.data.result &&
            res.data.result.bank_card_number.length > 0
          ) {
            that.bank.bankNumber = res.data.result.bank_card_number;
            that.bank.bankName = res.data.result.bank_name;
             this.$Loading.close()
            this.isloading = false;
            localStorage.setItem(
              "data",
              JSON.stringify({
                bankNumber: that.bank.bankNumber,
                bankName: that.bank.bankName
              })
            );
            // this.formdata.name = res.data.result.bank_card_number;
          } else {
            this.$Loading.close()
            throw new Error("返回数据不合法");
          }
          // localStorage.setItem(
          //   "idcrad",
          //   JSON.stringify({ name: userName,Bankcardnumber: Bankcard })
          // );
        })
        .catch(error => {
          console.error(error);
          that.bank.bankNumber = "";
          that.isloading = false;
          that.$toast("图片上传错误");
          //  return that.$toast("图片上传错误"), !1;
        });
    },
    }
}
</script>

<style lang="scss" scoped>
::v-deep{
    .van-cell{
        line-height:76px;
    }
    .van-cell:after{
        border-bottom:2px solid #E9E9E9;
    }
    .van-cell__title{
        width:150px;
        color: #242424;
        font-weight: 500;
        font-size: 32px;
    }
    .van-field__control{
        color: #666666;
        font-size: 32px;
        font-weight: 400;
        line-height: 80x;
    }
    .van-icon{
        font-size: 48px;
        color: #ff2d2e;
    }
    .van-uploader{
            position: absolute;
            top: 26%;
            right: 0;
    }
    .el-checkbox__inner{
        border-radius: 50%;
        border-color:1px solid #e9e9e9;
    }
    .el-checkbox__inner:hover {
        border-color: #e9e9e9!important;
    }
    .is-checked{
        .el-checkbox__inner{
                background-color: #ff2d2e !important;
        }
    }
}
.bg_gray {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
.addBankCard{
  position: relative;
  z-index: 1;
}
.bankName{
    position: relative;
}
.box{
    padding: 30px;
    width: 100%;
    .bank{
            width: 100%;
            background-color: #fff;
            border-radius: 8px;
            padding: 10px 18px;
            position: relative;
    }
    .save{
        height: 84px;
        background: #f7cdcd;
        border-radius: 50px;
        font-size: 32px;
        color: #FFFFFF;
        line-height: 84px;
        text-align: center;
        margin-top: 80px;
    }
}
.check{
    height: 70px;
    padding: 14px;
}
</style>
