var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bg_gray" }),
    _c(
      "div",
      { staticClass: "addBankCard" },
      [
        _c("headbox", {
          attrs: {
            title: "新增银行卡",
            bgColor: "#fff",
            isbackPath: true,
            path: "bank"
          }
        }),
        _c("div", { staticClass: "box" }, [
          _c(
            "div",
            { staticClass: "bank" },
            [
              _c("van-field", {
                attrs: {
                  label: "真实姓名",
                  placeholder: "请输入",
                  disabled: _vm.isDisable
                },
                model: {
                  value: _vm.bank.name,
                  callback: function($$v) {
                    _vm.$set(_vm.bank, "name", $$v)
                  },
                  expression: "bank.name"
                }
              }),
              _c(
                "div",
                { staticClass: "bankName" },
                [
                  _c("van-field", {
                    attrs: { label: "银行名称", placeholder: "请输入" },
                    model: {
                      value: _vm.bank.bankName,
                      callback: function($$v) {
                        _vm.$set(_vm.bank, "bankName", $$v)
                      },
                      expression: "bank.bankName"
                    }
                  }),
                  _c(
                    "van-uploader",
                    {
                      ref: "uploader",
                      attrs: {
                        slot: "suffix",
                        "after-read": _vm.afterRead,
                        "preview-image": false
                      },
                      slot: "suffix"
                    },
                    [_c("van-icon", { attrs: { name: "scan" } })],
                    1
                  )
                ],
                1
              ),
              _c("van-field", {
                attrs: { label: "银行卡号", placeholder: "请输入" },
                model: {
                  value: _vm.bank.bankNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.bank, "bankNumber", $$v)
                  },
                  expression: "bank.bankNumber"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "save",
              style: { background: _vm.isSave ? "#ff2d2e" : "#f7cdcd" },
              on: {
                click: function($event) {
                  return _vm.save(_vm.type)
                }
              }
            },
            [_vm._v(_vm._s(_vm.saveName))]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }